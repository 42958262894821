import React from 'react';
import Home from './pages/home/home.component';
import Navbar from './components/navbar/navbar.component';
import About from './pages/about/about.component';
import Experience from './pages/experience/experience.component';
import Projects from './pages/projects/projects.component';
import Contact from './pages/contact/contact.component';
import Footer from './components/footer/footer.component';
import { ThemeProvider } from './context/ThemeContext';

function App() {
	return (
		<ThemeProvider>
			<div className='App'>
				<Navbar />
				<Home />
				<About />
				<Experience />
				<Projects />
				<Contact />
				<Footer />
			</div>
		</ThemeProvider>
	);
}

export default App;
