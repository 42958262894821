import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Enable transitions after initial load
window.addEventListener('DOMContentLoaded', () => {
	document.documentElement.classList.remove('no-theme-transition');
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);
