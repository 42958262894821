import './contact.styles.scss';
import React, { FC, FormEvent, useState } from 'react';
import { FaMapMarkerAlt, FaEnvelopeOpenText } from 'react-icons/fa';

const Contact: FC = () => {
	const [form, setForm] = useState({ name: '', email: '', message: '' });
	const [popupMessage, setPopupMessage] = useState('');

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();

		// Serialize form data
		const formData = new FormData();
		formData.append('name', form.name);
		formData.append('email', form.email);
		formData.append('message', form.message);

		// Send form data to Formspree using AJAX
		fetch('https://formspree.io/f/xyyabpej', {
			method: 'POST',
			body: formData,
			headers: {
				Accept: 'application/json',
			},
		})
			.then((response) => {
				if (response.ok) {
					setPopupMessage('Your message has been sent!');
					setForm({ name: '', email: '', message: '' });
				} else {
					setPopupMessage('An error occurred. Please try again later.');
				}
			})
			.catch(() => {
				setPopupMessage('An error occurred. Please try again later.');
			});

		setTimeout(() => {
			setPopupMessage('');
		}, 3000);
	};

	return (
		<>
			<section id='contact' className='contact-section'>
				<div className='contact-container'>
					<h1 className='section-title'>Contact Me</h1>
					<h2 className='section-sub-title'>Drop me a message! 👇</h2>
					<div>
						<form
							id='contact-form'
							className='contact-form'
							onSubmit={handleSubmit}
						>
							<label htmlFor='name' className='form-label'>
								Name:
							</label>
							<input
								type='text'
								id='name'
								name='name'
								className='form-input'
								required
								value={form.name}
								onChange={handleChange}
							/>
							<label htmlFor='email' className='form-label'>
								Email:
							</label>
							<input
								type='email'
								id='email'
								name='email'
								className='form-input'
								required
								value={form.email}
								onChange={handleChange}
							/>
							<label htmlFor='message' className='form-label'>
								Message:
							</label>
							<textarea
								id='message'
								name='message'
								className='form-input'
								rows={4}
								required
								value={form.message}
								onChange={handleChange}
							></textarea>
							<div className='contact-button-container'>
								<button type='submit' className='form-button'>
									Send
								</button>
							</div>
						</form>
					</div>
					<div className='contact-details'>
						<div className='contact-details-container'>
							<div className='contact-icons'>
								<a
									href='https://goo.gl/maps/CtmnWMh4diDfhzAX8'
									target='_blank'
									rel='noopener noreferrer'
								>
									<div className='contact-icon-container'>
										<FaMapMarkerAlt className='icon' />
									</div>
								</a>
								<div className='contact-text'>
									<h3>Location</h3>
									<p>Manchester, England</p>
								</div>
							</div>
							<div className='contact-icons'>
								<a href='mailto:simone.melidoni@outlook.com'>
									<div className='contact-icon-container'>
										<FaEnvelopeOpenText className='icon' />
									</div>
								</a>
								<div className='contact-text'>
									<h3>Email</h3>
									<p>simone.melidoni@outlook.com</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{popupMessage && <div className='contact-popup'>{popupMessage}</div>}
		</>
	);
};

export default Contact;
