import React from 'react';
import { Expand } from '@theme-toggles/react';
import '@theme-toggles/react/css/Expand.css';
import { useTheme } from '../../context/ThemeContext';
import './theme-toggle.styles.scss';

const ThemeToggle: React.FC = () => {
	const { theme, toggleTheme } = useTheme();

	return (
		<div className='theme-toggle-container'>
			<Expand
				toggled={theme === 'dark'}
				toggle={toggleTheme}
				duration={750}
				aria-label={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
			/>
		</div>
	);
};

export default ThemeToggle;
