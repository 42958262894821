import './footer.styles.scss';

import React from 'react';
import { AiOutlineGithub, AiOutlineLinkedin } from 'react-icons/ai';

const Footer = () => (
	<div className='footer-container'>
		<div className='footer-content'>
			<a
				href='https://github.com/smelidoni'
				target='_blank'
				rel='noreferrer'
				className='icon-link'
			>
				<AiOutlineGithub size={30} />
			</a>
			<a
				href='https://www.linkedin.com/in/simone-melidoni/'
				target='_blank'
				rel='noreferrer'
				className='icon-link'
			>
				<AiOutlineLinkedin size={30} />
			</a>
		</div>
		<div className='footer-text'>
			<p>Designed & Built by Simone Melidoni</p>
			<p>&copy; {new Date().getFullYear()} All rights reserved.</p>
		</div>
	</div>
);

export default Footer;
