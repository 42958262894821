import './about.styles.scss';
import React, { FC } from 'react';

import programmerGif from '../../assets/gif/programmer.gif';

const About: FC = () => {
	return (
		<section id='about' className='about-section'>
			<div className='about-container'>
				<img className='about-gif' src={programmerGif} alt='Programmer' />
				<div className='about-main'>
					<h1>About Me</h1>
					<h2>An innovative and dedicated software engineer &#x1F4A1;</h2>
					<p>
						Embarking on my tech journey over 4 years ago, I've immersed myself
						in the world of software development, gaining a deep understanding
						of the fundamentals.
					</p>
					<p>
						My technical expertise encompasses a wide range of technologies,
						including React, Redux, HTML, CSS/SASS, JavaScript and TypeScript.
						In addition, I utilise Figma, a powerful UI design tool, to
						transform wireframes into visually appealing and user-friendly
						interfaces.
					</p>
					<p>
						Known for my friendly nature, strong problem-solving skills, and
						dedication to creating accessible websites and applications, I am
						truly passionate about what I do. My enthusiasm for software
						development drives me to stay updated with the latest tech trends,
						constantly expanding my knowledge and skills.
					</p>
				</div>
			</div>
		</section>
	);
};

export default About;
