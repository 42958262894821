import './navbar.styles.scss';
import React, { FC, useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { MdMenu, MdClose } from 'react-icons/md';
import ThemeToggle from '../theme-toggle/theme-toggle.component';
import useMediaQuery from '../../hooks/useMediaQuery';

const links = ['home', 'about', 'experience', 'projects', 'contact'];

const Navbar: FC = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const isDesktop = useMediaQuery('(min-width: 1025px)');

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};

	useEffect(() => {
		if (isDesktop && menuOpen) {
			setMenuOpen(false);
		}
	}, [isDesktop, menuOpen]);

	const SCROLL_DURATION = 700;

	return (
		<nav className='navbar-container'>
			<div className='navbar-name'>
				<Link
					className='navbar-name-link'
					to='home'
					smooth={true}
					offset={-70}
					duration={SCROLL_DURATION}
				>
					<b>simone.melidoni</b>
				</Link>
			</div>
			<button onClick={toggleMenu} className='menu-button'>
				<MdMenu size={32} />
			</button>
			<div className='navbar-links-container'>
				<div className='navbar-links'>
					{links.map((link) => (
						<Link
							key={link}
							activeClass='active'
							to={link}
							spy={true}
							smooth={true}
							offset={-70}
							duration={SCROLL_DURATION}
						>
							{link.charAt(0).toUpperCase() + link.slice(1)}
						</Link>
					))}
				</div>
				{isDesktop && (
					<div className='navbar-theme-toggle-desktop'>
						<ThemeToggle />
					</div>
				)}
			</div>

			<div className={`mobile-menu ${menuOpen ? 'open' : ''}`}>
				{menuOpen && (
					<>
						<button onClick={toggleMenu} className='close-button'>
							<MdClose size={32} />
						</button>
						{links.map((link) => (
							<Link
								key={link}
								activeClass='active'
								to={link}
								spy={true}
								smooth={true}
								offset={-70}
								duration={SCROLL_DURATION}
								onClick={toggleMenu}
							>
								{link.charAt(0).toUpperCase() + link.slice(1)}
							</Link>
						))}
						{!isDesktop && (
							<div className='navbar-theme-toggle-mobile'>
								<ThemeToggle />
							</div>
						)}
					</>
				)}
			</div>
		</nav>
	);
};

export default Navbar;
