import './projects.styles.scss';

import React, { FC } from 'react';
import {
	FaGithub,
	FaExternalLinkAlt,
	FaSpinner,
	FaWrench,
} from 'react-icons/fa';

import ProjectImageCard from '../../components/project-image-card/project-image-card.component';

import minimalTechSnippet from '../../assets/images/project-snippets/minimaltech-snippet.png';
import personalFinanceManagerSnippet from '../../assets/images/project-snippets/personal-finance-manager-snippet.png';
import coinpulseCryptoAppSnippet from '../../assets/images/project-snippets/coinpulse-crypto-app-snippet.png';
import reactAppCollectionSnippet from '../../assets/images/project-snippets/react-app-collection-snippet.png';

const Projects: FC = () => {
	return (
		<section id='projects' className='projects-section'>
			<div className='projects-container'>
				<h1 className='section-title'>Projects</h1>
				<div className='project-card'>
					<ProjectImageCard
						imgSrc={minimalTechSnippet}
						// link='https://www.minimaltech.co.uk'
						altTag='minimaltech-project'
					/>
					<div className='project-details'>
						<h3 className='project-title'>MinimalTech 🛍️</h3>
						<p className='project-description'>
							MinimalTech is an online e-commerce store that specialises in
							providing minimal tech accessories for the modern professional.
						</p>
						<div className='project-languages'>
							<span>Shopify</span>
						</div>
						<div className='project-links'>
							{/* <a
								href='https://minimaltech.co.uk/'
								target='_blank'
								rel='noreferrer'
							>
								Website <FaExternalLinkAlt />
							</a> */}
							<span>
								Under Maintenance... <FaWrench />
							</span>
						</div>
					</div>
				</div>
				<div className='project-card'>
					<ProjectImageCard
						imgSrc={personalFinanceManagerSnippet}
						// link='https://www.google.com'
						altTag='personal-finance-manager-project'
					/>
					<div className='project-details'>
						<h3 className='project-title'>Personal Finance Manager 💰</h3>
						<p className='project-description'>
							Personal Finance Manager is a web application designed to help
							users manage their personal finances effectively. The app provides
							an easy-to-use interface to track income, expenses, savings, and
							investments.
						</p>
						<div className='project-languages'>
							<span>React</span>
							<span>TypeScript</span>
							<span>Node.js</span>
							<span>Express.js</span>
						</div>
						<div className='project-links'>
							<span>
								In Progress... <FaSpinner />
							</span>
						</div>
					</div>
				</div>
				<div className='project-card'>
					<ProjectImageCard
						imgSrc={coinpulseCryptoAppSnippet}
						link='https://coinpulse-crypto-app.vercel.app/'
						altTag='personal-finance-manager-project'
					/>
					<div className='project-details'>
						<h3 className='project-title'>CoinPulse Crypto App 🪙</h3>
						<p className='project-description'>
							CoinPulse is an application which provides real-time data on the
							top 50 cryptocurrencies, ensuring you stay ahead in the dynamic
							world of cryptocurrency.
						</p>
						<div className='project-languages'>
							<span>React</span>
							<span>TypeScript</span>
							<span>CoinGecko API</span>
						</div>
						<div className='project-links'>
							<a
								href='https://github.com/SMelidoni/coinpulse-crypto-app'
								target='_blank'
								rel='noreferrer'
							>
								Code <FaGithub />
							</a>
							<a
								href='https://coinpulse-crypto-app.vercel.app/'
								target='_blank'
								rel='noreferrer'
							>
								Live Demo <FaExternalLinkAlt />
							</a>
						</div>
					</div>
				</div>
				<div className='project-card'>
					<ProjectImageCard
						imgSrc={reactAppCollectionSnippet}
						link='https://react-app-collection.vercel.app/'
						altTag='react-app-collection-project'
					/>
					<div className='project-details'>
						<h3 className='project-title'>React App Collection 🕹️</h3>
						<p className='project-description'>
							React App Collection is an ongoing web application that showcases
							a collection of individual projects built using React. It features
							a variety of applications, including a Calculator, Tic-Tac-Toe,
							and To Do App.
						</p>
						<div className='project-languages'>
							<span>React</span>
							<span>TypeScript</span>
						</div>
						<div className='project-links'>
							<a
								href='https://github.com/SMelidoni/react-app-collection'
								target='_blank'
								rel='noreferrer'
							>
								Code <FaGithub />
							</a>
							<a
								href='https://react-app-collection.vercel.app/'
								target='_blank'
								rel='noreferrer'
							>
								Live Demo <FaExternalLinkAlt />
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Projects;
