import './project-image-card.styles.scss';

import React, { FC, useEffect, useRef, useState } from 'react';

interface ProjectImageCardProps {
	imgSrc: string;
	link?: string;
	altTag: string;
}

const ProjectImageCard: FC<ProjectImageCardProps> = ({
	imgSrc,
	link,
	altTag,
}) => {
	const imgRef = useRef<HTMLImageElement>(null);
	const divRef = useRef<HTMLDivElement>(null);
	const [scrollDistance, setScrollDistance] = useState(0);

	useEffect(() => {
		const currentImgRef = imgRef.current;
		const currentDivRef = divRef.current;
		if (currentImgRef && currentDivRef) {
			const calculateScrollDistance = () => {
				const containerHeight = (currentImgRef.offsetParent as HTMLElement)
					.clientHeight;
				const imgHeight = currentImgRef.clientHeight;
				setScrollDistance(imgHeight - containerHeight);

				// Only set the height if the viewport width is less than 425px
				if (window.innerWidth < 545) {
					const newHeight = Math.min(imgHeight, 400);
					currentDivRef.style.height = `${newHeight}px`;
				} else {
					currentDivRef.style.height = `350px`;
				}
			};

			// Listen for 'load' and 'resize' events
			currentImgRef.addEventListener('load', calculateScrollDistance);
			window.addEventListener('resize', calculateScrollDistance);

			return () => {
				currentImgRef.removeEventListener('load', calculateScrollDistance);
				window.removeEventListener('resize', calculateScrollDistance);
			};
		}
	}, [imgSrc]);

	useEffect(() => {
		if (scrollDistance > 0) {
			imgRef.current!.style.transition = `transform 5s ease-in-out`;
			imgRef.current!.onmouseover = () => {
				imgRef.current!.style.transform = `translateY(-${scrollDistance}px)`;
			};
			imgRef.current!.onmouseout = () => {
				imgRef.current!.style.transform = 'translateY(0)';
			};
		}
	}, [scrollDistance]);

	return (
		<div className='project-image' ref={divRef}>
			<a href={link} target='_blank' rel='noopener noreferrer'>
				<img ref={imgRef} src={imgSrc} alt={altTag} />
			</a>
		</div>
	);
};

export default ProjectImageCard;
